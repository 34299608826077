import { Button } from '../../form';

import classes from './Support.module.css';

const Support = () => {
    return (
        <div className={classes.root}>
            <h2 className={classes.title}>Vragen of hulp nodig?</h2>
            <div>
                <p>
                    Bij HANOS Kerstpakketten staat waardering voor uw team centraal. Onze kerstteams in alle HANOS vestigingen nemen graag de tijd voor u. Zo geeft u uw medewerkers en relaties tijdens de kerstdagen de blijk van waardering die iedereen verdient!
                </p>
                <Button variant="transparent" to="/contact">
                    {
                        <span>Neem contact op met onze kerstspecialist</span>
                    }
                </Button>
            </div>
        </div>
    );
}

export default Support;